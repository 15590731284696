;(function() {
"use strict";

const contactEventListComponent = {
  templateUrl:  'components/household/event/contact-event-list/contact-event-list.component.html',
  controller:   'ContactEventListController',
  controllerAs: 'vm',
  require: {
    parent: '^householdEvent'
  },
  bindings: {
    contacts: '<',
    household: '<'
  }
};

function contactEventListController($anchorScroll, $filter, $timeout, $location, $log, $uibModal, NgTableParams) {
  let vm = this;

  vm.$onChanges = function(changedObj) {
    if (changedObj.contacts && changedObj.contacts.currentValue) {
      vm.contacts = angular.copy(changedObj.contacts.currentValue);
      _.each(vm.contacts, annotateContact);
      vm.tableParams = new NgTableParams({
        count: vm.contacts.length,
        sorting: { contact_time: "desc" }
      }, {
        dataset: vm.contacts,
        counts: [], // hides page sizes
      });
    }
  };

  function annotateContact(entry) {
    if (entry.contact) {
      annotateContactEvent(entry.contact);
    }
    else {
      annotateScheduledEvent(entry.scheduled_event);
    }

    if (entry.warnings) {
      for (const warning of entry.warnings) {
        if (warning.related_event_index >= 0) {
          warning.rowId = makeRowId(warning.related_event_index);
          warning.icon = warning.related_event_index < entry.index
            ? 'icon-tmr-level-up' : 'icon-tmr-level-down';
        }
      }
    }
  }

  function annotateContactEvent(entry) {
    entry.rowId = makeContactEventRowId(entry.contact_id);

    const action = entry.contact_type_id == 1 ? 'completed' : 'conducted';
    const msg = `${ entry.contact_type } ${ action } on ${ formatDateTime(entry.contact_time) }`;
    entry.description = msg;

    const now = moment();
    entry.isOverDue = moment(entry.contact_time).isBefore(now, 'day');

    entry.coloredOutcomeLabel = entry.contact_outcome == 'Completed' || entry.contact_outcome == 'Validated';

    // attached child scheduled event
    const children = getScheduledEventsByContact(entry, true);
    if (children.length > 0) {
      entry.children = children;
    }

    // attached resolving scheduled event
    if (!_.isNil(entry.resolved_scheduled_event_index)) {
      const resolvedEvent = vm.contacts[entry.resolved_scheduled_event_index].scheduled_event;

      const time = formatScheduledEventTime(resolvedEvent);
      const interviewType = resolvedEvent.scheduled_event_type;
      const msg = `Resolves ${ interviewType } scheduled for ${ time }`;
      const id = makeScheduledEventRowId(resolvedEvent.scheduled_event_id);

      entry.resolvedScheduledEvent = {
        description: msg,
        rowId: id,
      };
    }
  }

  function annotateScheduledEvent(entry) {
    entry.rowId = makeScheduledEventRowId(entry.scheduled_event_id);

    let msg = `${ entry.scheduled_event_type } scheduled for ${ formatScheduledEventTime(entry) }`;
    if (!_.isNil(entry.assigned_to)) {
        msg += ` - ${ entry.assigned_to.full_name }`;
    }
    entry.description = msg;

    const parentContact = vm.contacts[entry.created_by_contact_index].contact;
    const parentContactTime = formatDateTime(parentContact.contact_time);
    const parentContactType = parentContact.contact_type;
    const parentDesc = `Scheduled via ${ parentContactType } on ${ parentContactTime }`;
    const parentId = makeContactEventRowId(parentContact.contact_id);

    entry.parentContact = {
      description: parentDesc,
      rowId: parentId,
    };

    // attached resolved contact
    if (!_.isNil(entry.resolved_by_contact_index)) {
      const resolvedContact = vm.contacts[entry.resolved_by_contact_index].contact;

      const contactTime = formatDateTime(resolvedContact.contact_time);
      const action = resolvedContact.contact_type_id == 1 ? 'completed' : 'conducted';

      const resolvedDesc = `Resolved by ${ resolvedContact.contact_type } ${ action } on ${ contactTime }`;
      const id = makeContactEventRowId(resolvedContact.contact_id);
      entry.resolvedByContact = {
        description: resolvedDesc,
        rowId: id,
      };
    }
    else {
      // label for 'due', 'overdue' for unresolved scheduled event
      const now = moment();
      entry.isDue = moment(entry.scheduled_for).isSame(now, 'day');
      entry.isOverDue = moment(entry.scheduled_for).isBefore(now, 'day');
    }

  }

  function makeContactEventRowId(contactId) {
    return 'contact-' + _.toString(contactId);
  }

  function makeScheduledEventRowId(scheduledEventId) {
    return 'scheduled-' + _.toString(scheduledEventId);
  }

  function makeRowId(index) {
    const event = vm.contacts[index];
    if (event.event_type == 'contact') {
      return makeContactEventRowId(event.contact.contact_id);
    }
    return makeScheduledEventRowId(event.scheduled_event.scheduled_event_id);
  }

  const formatDateTime = $filter('tmrDateTime');
  const formatScheduledEventTime = $filter('scheduledEventDateTime');

  vm.manageEditContact = function(contact) {
    if (_.isNil(contact.resolved_scheduled_event_index)) {
      vm.editContact(contact);
    }
    else {
      vm.editResolveContact(contact);
    }
  };

  vm.editContact = function(contact) {
    // TODO this is not a good way of doing this, altering parent values to show/hide bits
    const children_events = getScheduledEventsByContact(contact);
    vm.parent.contactToEdit = contact;
    if (children_events.length > 0) {
      vm.parent.contactToEdit.children = children_events;
    }
    vm.parent.onEditForm();
  };

  vm.deleteContact = function(contact) {
    let modalInst = $uibModal.open({
      component: 'deleteEntryModal',
      resolve: {
        title: () => {
          return "contact";
        },
        type: () => {
          return contact.contact_type;
        },
        time: () => {
          return contact.contact_time;
        },
        outcome: () => {
          return contact.contact_outcome.toLowerCase();
        },
        isResolvedContact: () => {
          return contact.resolved_scheduled_event_index !== null;
        },
      }
    });

    modalInst.result.then(function () {
      // If ok is hit
      vm.parent.deleteContact(contact);
    }, function () {
      // If cancel is hit
    });
  };

  vm.editScheduledEvent = function(entry) {
    let parent_contact = vm.contacts[entry.created_by_contact_index].contact;
    const children_events = getScheduledEventsByContact(parent_contact);
    parent_contact.children = children_events;
    // use this to scroll to the chosen scheduled event row
    parent_contact.idToFocus = entry.scheduled_event_id;
    if (!_.isNil(parent_contact.resolved_scheduled_event_index)) {
      // this scheduled event linked to a parent 'scheduled' contact that resolved a scheduled event
      // scheduled event is resolved by another scheduled event
      vm.parent.scheduledEventToResolve = vm.contacts[parent_contact.resolved_scheduled_event_index].scheduled_event;
      vm.parent.resolveContactToEdit = parent_contact;
      vm.parent.onResolveForm();
    }
    else {
      // an usual unresolved scheduled event
      vm.parent.contactToEdit = parent_contact;
      vm.parent.onEditForm();
    }
  };

  vm.deleteScheduledEvent = function(entry) {
    let modalInst = $uibModal.open({
      component: 'deleteEntryModal',
      resolve: {
        title: () => {
          return "schedule";
        },
        type: () => {
          return entry.scheduled_event_type;
        },
        time: () => {
          return entry.scheduled_for;
        },
      }
    });

    modalInst.result.then(function () {
      // If ok is hit
      vm.parent.deleteScheduledEvent(entry);
    }, function () {
      // If cancel is hit
    });
  };

  vm.resolveScheduledEvent = function(entry) {
    vm.parent.scheduledEventToResolve = entry;
    vm.parent.resolveContactToEdit = null;
    vm.parent.onResolveForm();
  };

  vm.editResolveContact = function(contact) {
    const entry = vm.contacts[contact.resolved_scheduled_event_index].scheduled_event;
    vm.parent.scheduledEventToResolve = entry;

    const children_events = getScheduledEventsByContact(contact);
    vm.parent.resolveContactToEdit = contact;
    if (children_events.length > 0) {
      vm.parent.resolveContactToEdit.children = children_events;
    }

    vm.parent.onResolveForm();
  };

  function className(string) {
    return string.toLowerCase().replaceAll(/[^a-z]+/g, '-')
  }

  vm.eventClass = function(event) {
    let classes = [ event.event_type ];

    if (event.event_type == 'contact') {
      const contact = event.contact;
      classes.push(contact.contact_type);
      classes.push(contact.contact_outcome);
    }
    else if (event.event_type == 'scheduled_event') {

      const sched = event.scheduled_event;
      classes.push(sched.scheduled_event_type);

      if (sched.resolved_by_contact_id) {
        classes.push('resolved');
        const resolved_by = vm.contacts[sched.resolved_by_contact_index];
        classes.push('resolved-by-' + resolved_by.contact.contact_type);
      }
      else {
        classes.push('unresolved');
        if (sched.isDue) {
          classes.push('due');
        }
        if (sched.isOverDue) {
          classes.push('overdue');
        }
      }

      if (sched.created_by_contact_id) {
        const created_by = vm.contacts[sched.created_by_contact_index];
        classes.push('created-by-' + created_by.contact.contact_type);
      }
    }

    const ret = classes.map(className).join(' ');
    //console.log(`Classes="${ ret }"`);
    return ret;
  }

  vm.focusRowEvent = function(id) {
    // Reference https://blog.lkatney.com/2017/02/15/focus-on-a-row-within-ng-repeat/
    $timeout(() => {
      // zero timeout so that this is handled in the next digest cycle
      $anchorScroll(id);
      vm.rowIdFocus = id;
    })
    .then(wait(1000))
    .then(() => {
      vm.rowIdFocus = null;
    });
  };

  vm.highlightScheduledEventBox = function(id) {
    $timeout(() => {
      // zero timeout so that this is handled in the next digest cycle
      vm.labelFocus = id;
    })
    .then(wait(1000))
    .then(() => {
      vm.labelFocus = null;
    });
  };

  function wait(millis) {
    // return a function that returns a promise
    return function() {
      return $timeout(millis);
    }
  }

  function getScheduledEventsByContact(contact, addDescription = false) {
    const children = [ ];
    if (!contact.created_scheduled_event_indexes) {
      return children;
    }
    _.each(contact.created_scheduled_event_indexes, (idx) => {
      let child = angular.copy(vm.contacts[idx].scheduled_event);

      if (addDescription) {
        // decorate to display on the interface
        child.description = `${ child.scheduled_event_type } scheduled for ${ formatScheduledEventTime(child) }`;
        if (!_.isNil(child.assigned_to)) {
          child.description += ` - ${ child.assigned_to.full_name }`;
        }
        child.rowId = makeScheduledEventRowId(child.scheduled_event_id);
        child.isResolved = !_.isNil(child.resolved_by_contact_index);
      }
      else {
        // parse to use in forms
        if (!_.isNil(child.resolved_by_contact_index)) {
          // if this scheduled event is resolved, grab a copu of the resolved contact
          child.resolved_by_contact = vm.contacts[child.resolved_by_contact_index].contact;
        }
        child.index = idx;
      }
      children.push(child);
    });
    return children;
  }

}

angular
    .module('tmr-admin')
    .component('contactEventList', contactEventListComponent)
    .controller('ContactEventListController', contactEventListController)
;
}());
